<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<movement-form-header :modelPk="modelPk"></movement-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#maintab'">{{ $t('form.movement.tabs.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#seqheader'" v-if="!isCreatingElement && hasMovSeqHeaderFunctionality">{{
					$t('form.movement.tabs.seqheader')
				}}</v-tab>
				<v-tab :key="2" :href="'#resourceallocation'" v-if="!isCreatingElement && !isModalDialog && hasResAllocationFunctionality">{{
					$t('form.movement.tabs.resourceallocation')
				}}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'maintab'">
					<v-flex xs12>
						<movement-form-main-tab
							:modelName="modelName"
							:parentObject="this.model"
							:formDisabled="formDisabled"
							:isCreatingElement="isCreatingElement"
							:pk="pk"
						></movement-form-main-tab>
					</v-flex>
				</v-tab-item>
				<v-tab-item :key="1" lazy :value="'seqheader'" v-if="!isCreatingElement && hasMovSeqHeaderFunctionality">
					<v-flex xs12>
						<pui-master-detail
							componentName="movementsequencestep-grid"
							parentModelName="movementsequenceheader"
							:parentPk="this.model.movementsequenceheaderid"
							:parentPkChildFk="{ id: 'seqstepheaderid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
							:parentModel="model"
						></pui-master-detail>
					</v-flex>
				</v-tab-item>
				<v-tab-item :key="2" lazy :value="'resourceallocation'" v-if="!isCreatingElement && !isModalDialog && hasResAllocationFunctionality">
					<v-flex xs12>
						<pui-master-detail
							componentName="resourceallocationgrid"
							parentModelName="movement"
							:parentPk="pk"
							:parentPkChildFk="{ id: 'movement_id' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
							:parentModel="model"
						></pui-master-detail>
					</v-flex>
				</v-tab-item>
			</v-tabs-items>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import MovementFormHeader from './MovementFormHeader.vue';
import MovementFormMainTab from './MovementFormMainTab.vue';

export default {
	name: 'movement-form',
	mixins: [PuiFormMethodsMixin],
	components: { MovementFormHeader, MovementFormMainTab },
	data() {
		return {
			modelName: 'movement'
		};
	},
	methods: {
		afterGetData() {
			//Get data
			const opts = {
				model: 'movementsequenceheader',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'movement_id',
							op: 'eq',
							data: this.model.id
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					this.model.movementsequenceheaderid = btoa('{ "id": ' + response.data.data[0].id + ' }');
					/*console.log('btoa!!!!->' + this.model.movementsequenceheaderid);
					console.log('atob!!!!->' + JSON.parse(atob(this.model.movementsequenceheaderid)).id);
					console.log('btoa!!!!->' + this.pk);
					console.log('atob!!!!->' + JSON.parse(atob(this.pk)).id);*/
				} else {
					this.model.movementsequenceheaderid = btoa('{ "id": null }');
				}
			});
		}
	},
	computed: {
		hasResAllocationFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_RESOURCEALLOCATION');
		},
		hasMovSeqHeaderFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_MOVEMENTSEQUENCEHEADER');
		}
	},
	created() {},
	watch: {}
};
</script>
